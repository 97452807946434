import AbHandler from '/components/AbHandler';
import { CLIENT_ID_KEY } from '/services/analytics/shinesty';
import Collapsable from '/components/landing-page/flyers/Collapsable';
import Head from '/components/Head';
import LandingPage from '/components/lp/LandingPage';
import MainLayout from '/layouts/MainLayout';
import PropTypes from 'prop-types';
import { identifyUser as identifyHeapUser } from '/services/analytics/heap';
import { identifyUser as identifyImpactUser } from '/services/analytics/impact';
import { identifyUser as identifyTatariUser } from '/services/analytics/tatari';
import { useEffect } from 'react';

import { getDefaultHomepage, getHomePage, getLandingPagePreview } from '/services/cms';

const Home = ({ clientId, homePage }) => {
	// this waits to send heap identify call until heap has loaded. need to write something to wait
	// for window functions to load but that is for another time
	useEffect(() => {
		setTimeout(function () {
			identifyHeapUser(clientId);
			identifyTatariUser(clientId);
			identifyImpactUser(clientId);
		}, 1000);
	}, []);

	return (
		<div className="mb-20">
			<Head />
			<AbHandler
				control={null}
				testId="homepage-quick-links"
				variations={{
					'show-quick-links': <Collapsable />,
				}}
			/>

			<LandingPage landingPage={homePage} />
		</div>
	);
};

export async function getServerSideProps(ctx) {
	let homePage;

	// TODO: Stacking Gifts - You need this for the if/else logic below
	let query = ctx.query;

	let clientIdRaw;

	ctx?.req?.rawHeaders?.map((item, i) => {
		if (item.toLowerCase() == CLIENT_ID_KEY.toLowerCase()) {
			clientIdRaw = ctx.req.rawHeaders[i + 1];
		}
	});

	const clientIdHeader = ctx?.res?.getHeader(CLIENT_ID_KEY);
	const clientId = clientIdRaw || clientIdHeader || ctx?.req?.cookies[CLIENT_ID_KEY];

	if (query.lpPreviewId) {
		homePage = await getLandingPagePreview(query.lpPreviewId);
	} else {
		homePage = await getHomePage(clientId);
	}

	// homePage = await getLandingPagePreview('649b0e7d514173b1ef7160e7');

	if (!homePage || !homePage?.sections || homePage?.sections?.length === 0) {
		const defaultHomepage = await getDefaultHomepage();
		homePage = defaultHomepage?.[0];
	}

	return {
		props: {
			clientId,
			homePage: homePage || null,
		},
	};
}

Home.propTypes = {
	clientId: PropTypes.string,
	homePage: PropTypes.object,
};

Home.getLayout = (page) => <MainLayout>{page}</MainLayout>;

export default Home;
