import Clickable from '/components/Clickable';
import Typography from '/components/Typography';
import { useState } from 'react';
import { AngleDownIcon, AngleUpIcon } from '/components/Icons';

const links = [
	{
		href: '/collections/mens-underwear-packs',
		title: 'Boxer Packs',
	},
	{
		href: '/products/monthly-boxer-subscription',
		title: 'Subscribe & Save 35%',
	},
	{
		href: '/products/build-a-boxer-pack',
		title: 'Build a Custom pack and save',
	},
	{
		href: '/collections/heel-hammock-ankle-socks',
		title: 'New: Socks',
	},
];

const Collapsable = () => {
	const [showCollapsed, setShowCollapsed] = useState(false);

	return (
		<div className="relative">
			<div className="flex md:hidden absolute w-full items-center align-middle justify-center z-[21] bg-gray-50">
				<Clickable
					className="flex items-center align-middle gap-2 py-4"
					onClick={() => setShowCollapsed(!showCollapsed)}>
					<Typography className="capitalize text-lg font-semibold">Quick Shop</Typography>
					{showCollapsed ? <AngleUpIcon /> : <AngleDownIcon />}
				</Clickable>
			</div>
			{showCollapsed && (
				<div className="grid absolute top-14 w-full bg-gray-50 z-20 animate-enter-top">
					{links.map((link, linkIndex) => {
						return (
							<Clickable
								key={linkIndex}
								className="w-full capitalize py-3 text-center font-semibold"
								heapEventName="Quick Link Click"
								heapEventData={{
									quickLink: link.href,
								}}
								linkToInternal={link.href}>
								{link.title}
							</Clickable>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Collapsable;
